.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body {
  font-family: 'Arial', sans-serif;
  background-color: #121212; /* Dark background for a techy feel */
  color: #ffffff; /* Light text color */
  margin: 0;
  padding: 0;
}

header {
  background-color: #1e1e1e; /* Slightly lighter background for header */
  padding: 20px;
  text-align: center;
}

nav ul {
  list-style-type: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin: 0 15px;
}

nav ul li a {
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s;
}

nav ul li a:hover {
  color: #61dafb; /* React blue for hover effect */
}

footer {
  background-color: #1e1e1e;
  text-align: center;
  padding: 10px;
  position: relative;
  bottom: 0;
  width: 100%;
}

h1 {
  font-size: 2.5em;
}

h2 {
  color: #61dafb; /* React blue for section headers */
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

@media (max-width: 600px) {
  nav ul li {
      display: block;
      margin: 10px 0;
  }
  
  h1 {
      font-size: 2em;
  }
}

.game-list {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  gap: 20px; /* Space between items */
  margin-top: 20px; /* Space above the game list */
}

.game-item {
  background-color: #1e1e1e; /* Dark background for game items */
  padding: 15px;
  border-radius: 5px; /* Rounded corners */
  transition: background-color 0.3s;
}

.game-item:hover {
  background-color: #61dafb; /* Change color on hover */
  color: #121212; /* Dark text color on hover */
}

h2 {
  margin-top: 20px; /* Space above section headers */
}

.hero-section {
  height: 400px; /* Adjust height as needed */
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  position: relative;
}

.hero-content {
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay for text readability */
  padding: 20px;
  border-radius: 10px;
}

h1 {
  font-size: 3em;
  margin: 0;
}

h2 {
  margin-top: 10px;
}

.social-media {
  margin-top: 10px;
}

.social-media a {
  color: #61dafb; /* React blue */
  margin: 0 10px;
  text-decoration: none;
}

.social-media a:hover {
  text-decoration: underline; /* Underline on hover */
}
